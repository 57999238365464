<template>
  <div v-if="!loading">
    <worksheet-header
      :worksheet="worksheet"
      :worksheetTypeFields="worksheetTypeFields"
      :sum.sync="sum"
      :productInfo="productInfo"
      @jobUpdated="emitChange"
    />

    <hr />

    <div class="text-center" v-if="worksheet.status == 'SUBMITTED' || worksheet.status == 'USED'">
      <v-alert
        outlined
        color="success"
        class="mb-7 text-center"
      >
        <span v-if="worksheet.status == 'SUBMITTED'" class="my-auto">{{ $vuetify.lang.t("$vuetify.worksheet.submittedOn") }}
          {{ worksheet.submittedTime | formatDateTime }}</span>
        <span v-else>Worksheet Used</span>
        <v-btn @click="toggleStatus" class="ma-4" outlined v-if="isManagement && worksheet.status == 'SUBMITTED'">Change Status</v-btn>
      </v-alert>

    </div>

    <v-fade-transition hide-on-leave="true">
      <worksheet-settings v-if="stage == 0 && hasStage0" @onComplete="completePrerequisite()" :worksheet.sync="worksheet" :worksheetTypeFields="worksheetTypeFields" :productInfo="productInfo"/>
    </v-fade-transition>

    <v-fade-transition hide-on-leave="true">
      <worksheet-entry
        v-if="stage == 1 && worksheet.status == 'PENDING'"
        :worksheet="worksheet"
        :worksheetTypeFields="worksheetTypeFields"
        :sum.sync="sum"
        :currentEntry.sync="currentEntry"
        :productInfo="productInfo"
        :key="currentEntry.id"
      />
    </v-fade-transition>

    <v-divider class="mt-4" />

    <div class="d-flex justify-space-between">
      <div v-if="worksheet.memo">
        <v-subheader>
          {{$vuetify.lang.t('$vuetify.base.memo')}}:
          <div class="ml-2">
            {{ worksheet.memo }}
          </div>
        </v-subheader>
      </div>
    </div>

    <v-bottom-navigation grow app>
      <v-btn v-if="(stage == 1 && hasStage0) || (worksheet.status == 'SUBMITTED' && isManagement)" @click="stage = 0">
        <span>{{
          $vuetify.lang.t("$vuetify.base.settings")
        }}</span>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!hasEntries"
        @click="deleteWorksheet(worksheet)"
        >
        <span>{{ $vuetify.lang.t("$vuetify.base.delete") }}</span>
        <v-icon color="red">mdi-trash-can</v-icon>
        </v-btn
      >
      <v-btn @click="entriesDialog = true" v-if="hasEntries">
        <span>
          {{ worksheet.entries.length }}
          {{ $vuetify.lang.t("$vuetify.base.entries") }}</span
        >
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn @click="timestamp = Date.now(); notesDialog = true" v-if="worksheetTypeFields.hasNotes">
        <span>{{ $vuetify.lang.t("$vuetify.base.notes") }}</span>
        <v-icon>mdi-note-edit-outline</v-icon>
      </v-btn>
      <v-btn v-if="stage == 0 && !submitted" @click="completePrerequisite()">
        <span>{{
          $vuetify.lang.t("$vuetify.base.entries")
        }}</span>
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="loading"
        v-if="hasEntries && !submitted"
        @click="submitWorksheet()"
        ><span>{{ $vuetify.lang.t("$vuetify.base.submit") }}</span>
        <v-icon>mdi-arrow-up-circle-outline</v-icon></v-btn
      >
    </v-bottom-navigation>

    <v-dialog
      style="z-index: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="entriesDialog"
    >
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-btn icon @click="entriesDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <worksheet-items
            :worksheet.sync="worksheet"
            :sum.sync="sum" 
            :worksheetTypeFields="worksheetTypeFields"
            :productInfo="productInfo"
            :editable="true"
            @editEntry="editEntry"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog
      style="z-index: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="preSubmitDialog"
    >
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-btn icon @click="preSubmitDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
            <worksheet-entry-pre-submit :worksheet.sync="worksheet" :sum="sum" :weightUnit="weightUnit"/>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn class="ma-8" @click="preSubmitDialog = false">{{$vuetify.lang.t("$vuetify.base.cancel")}}</v-btn>
          <v-btn class="ma-8" color="primary" @click="submitWorksheet()">{{$vuetify.lang.t("$vuetify.base.submit")}} {{$vuetify.lang.t("$vuetify.worksheet.worksheet")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <worksheet-notes v-model="notesDialog" :worksheet="worksheet" :key="timestamp"/>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getProcessWorksheet } from "@/services/processWorksheet";
import { sumWorksheetWeight} from "@/services/worksheetServices"

const WorksheetSettings = () =>
  import("@/components/processWorksheet/WorksheetSettings.vue");
const WorksheetEntryPreSubmit = () =>
  import("@/components/processWorksheet/WorksheetEntryPreSubmit.vue");
const WorksheetNotes = () =>
  import("@/components/processWorksheet/WorksheetNotes.vue");
const WorksheetHeader = () =>
  import("@/components/processWorksheet/WorksheetHeader.vue");
const WorksheetEntry = () =>
  import("@/components/processWorksheet/WorksheetEntry.vue");
const WorksheetItems = () =>
  import("@/components/processWorksheet/WorksheetItems.vue");
  
export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      loading: true,
      validGrossWeight: false,
      showEntryNotes: false,
      addTime: Date.now(),
      currentEntry: null,
      entriesDialog: false,
      preSubmitDialog: false,
      notesDialog: false,
      sum: { grossWeight: 0, netWeight: 0 },
      imageData: null,
      productInfo: null,
      timestamp: Date.now(),
      stage: 0, // 0 - supplier/weight-settings, 1 - enter weight;  2 - pre-submission
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser", "userRoles"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    isManagement(){
      const permissibleRoles = ['Administrator', 'Manager', 'Factory'];
      if(this.userRoles && this.userRoles.some(r=> permissibleRoles.includes(r))){
        return true;
      }
      else{ return false; }
    },
    weightUnit(){
      if(this.productInfo){
        return this.productInfo.measureType == 'Unit' ? this.productInfo.measureUnit:this.productInfo.measureWeight;
      }
      else{
        return null;
      }
    },
    entryIconsLayout() {
      const hasFiles =
        this.currentEntry.files && this.currentEntry.files.length > 0;
      const hasNotes = this.showEntryNotes || this.currentEntry.notes;
      const useColumn = hasFiles || hasNotes;
      return useColumn ? "flex-column" : "flex-row";
    },
    netWeight() {
      return (
        (this.currentEntry.grossWeight ? this.currentEntry.grossWeight : 0) -
        this.worksheet.deductibleWeight
      );
    },
    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
    hasStage0() {
      if(this.worksheet.status == "SUBMITTED"){
        return true;
      }

      if(this.worksheetTypeFields){
        return this.worksheetTypeFields.hasWeightSettings ||
          this.worksheetTypeFields.hasSupplier ||
          this.worksheetTypeFields.hasCustomer ||
          this.worksheetTypeFields.hasDocumentNumber
      }
      else{ return false; }
      
    },
    hasPreSubmit(){
      const hasNetAdjWeight = this.worksheetTypeFields.hasNetAdjustmentWeight;
      return hasNetAdjWeight;
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.worksheet.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.worksheet.worksheetTypeId
        );
      } else {
        return {};
      }
    },

    submitted() {
      if (this.worksheet && ["SUBMITTED", "USED"].includes(this.worksheet.status)) {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  components: {
    WorksheetItems,
    WorksheetNotes,
    WorksheetHeader,
    WorksheetEntry,
    WorksheetEntryPreSubmit,
    WorksheetSettings,
  },
  async created() {

    await getProcessWorksheet(this.worksheet.id)
        .then((response) => {
          this.worksheet = response.data;
        })

    await this.fetchWorksheetTypeDefinitions();
    await this.loadProductInfo();

    if (this.hasEntries || !this.hasStage0) {
      this.currentEntry = {};
      this.stage = 1;
    }

    if (
      this.worksheet &&
      this.worksheet.status == "SUBMITTED" &&
      this.worksheetTypeFields.hasNotes
    ) {
      this.stage = 2;
    }

    this.sumWeight();

    this.loading = false;
  },
  methods: {
    ...mapActions("processWorksheet", [
      "submitProcessWorksheet",
      "deleteProcessWorksheet",
      "updateProcessWorksheet"
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    completePrerequisite(){
      this.sumWeight();
      this.currentEntry = {};
      this.stage = 1;
    },
    async submitWorksheet() {
      let confirm = false;
      //check if worksheet has pre-submit module
      if(this.hasPreSubmit && !this.preSubmitDialog){
        this.preSubmitDialog = true;
      }
      else{ 
        confirm = await this.$refs.confirm.open(
          this.$vuetify.lang.t("$vuetify.base.confirmSubmission"),
          this.$vuetify.lang.t("$vuetify.worksheet.submitConfirmMsg")
        );
      }

      if (confirm) {
        // this.worksheet.status = "SUBMITTED";
        this.submitProcessWorksheet(this.worksheet)
          .then((submittedWorksheet) => {
            this.addSuccessMessage(
              this.$vuetify.lang.t("$vuetify.worksheet.submitSuccessMsg")
            );

            this.worksheet = Object.assign({}, submittedWorksheet);
            // this.worksheet.submittedTime = new Date();
            // this.worksheet.status = "SUBMITTED";
            this.$emit("submitted", this.worksheet);
            if(this.preSubmitDialog){
              this.preSubmitDialog = false;
            }
          })
          .catch(() => {
            this.addErrorMessage("Error submitting worksheet");
          });
      }
    },
    async sumWeight() {
      this.sum = await sumWorksheetWeight(this.worksheet);
    },
    async loadProductInfo(){
      if(this.worksheet.productId){
        return await this.fetchCategory(this.worksheet.productId)
          .then((resp) => {
            this.productInfo = resp;

            return this.productInfo;
          })
      }
      else{ return null;}
    },
    async deleteWorksheet(worksheet) {
      let confirm = await this.$refs.confirm.open(
        this.$vuetify.lang.t('$vuetify.base.confirmDelete'),
        this.$vuetify.lang.t('$vuetify.worksheet.deleteConfirmMsg')
      );
      if (confirm) {
        this.deleteProcessWorksheet(worksheet.id)
          .then(() => {
            this.addSuccessMessage(this.$vuetify.lang.t('$vuetify.worksheet.deleteSuccessMsg'));
            this.$emit("deleted", worksheet);
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    editEntry(entry){
      if(entry && entry.id){
        this.currentEntry = entry;
        this.entriesDialog = false;
      }
    },
    async toggleStatus(){
      const originalStatus = this.worksheet.status;
      
      let toggleStatus = 'PENDING';

      switch(originalStatus){
        case 'SUBMITTED':
          toggleStatus = 'PENDING';
          break;
        case 'PENDING':
        case 'USED':
          toggleStatus = 'SUBMITTED';
          break;
      }
      
      let confirm = await this.$refs.confirm.open(
        "Confirm Change Status",
        "Please confirm you would like to change status to " + toggleStatus
      );
      
      if (confirm) {
        if(originalStatus == 'USED'){
          this.$axios.put(`/processWorksheet/unmark-used/${this.worksheet.id}`)
          .then(() => {
            this.worksheet.status = toggleStatus;
            this.addSuccessMessage("Worksheet status updated successfully");
          })
          .catch((error) => {
            console.log(error);
            this.worksheet.status = originalStatus;
            this.addErrorMessage("Error updating worksheet status");
          });
        }
        else{ 
          this.worksheet.status = toggleStatus;
          this.updateProcessWorksheet(this.worksheet)
            .then(() => {
              this.addSuccessMessage("Worksheet status updated successfully");
            })
            .catch((error) => {
              console.log(error);
              this.worksheet.status = originalStatus;
              this.addErrorMessage("Error updating worksheet status");
            });
        }

        if(toggleStatus == 'PENDING'){
          this.stage = 1;
        }
      }
    },
    emitChange(){
      this.$emit('updated')
    }
  },
};
</script>

<style></style>
